import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import Header from "components/common/Header/Header";
import SideBar from "components/common/SideBar/SideBarNew";
import SelectOption from "components/common/Select/Select";
import {
  emptyPaginationObj,
  ROSTERING_TIMESHEET,
  ROSTERING_TIMESHEET_TABS,
} from "Constants/constant";
import {
  ROSTERING_BREADCRUMB,
  ROSTERING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Breadcrumb from "components/common/Breadcrumb";
import TimeSheetTabs from "./TimeSheetTabs/TimesheetTabs";
import TimesheetContent from "./TimeSheetTabs/TimesheetContent";
import TimesheetHistory from "./TimeSheetTabs/TimesheetHistory";
import TimesheetComment from "./TimeSheetTabs/TimesheetComment";
import DateRangeSelect from "components/common/Select/DateRangeSelect";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TimeSheetSideBar from "./TimeSheetSideBar";
import { ImBlocked } from "react-icons/im";
import { BiExit } from "react-icons/bi";
import { useNavigate } from "react-router";
import ApproveTimeSheetModal from "./ApproveTimeSheetModal/ApproveTimeSheetModal";
import { apiGetRequest } from "helpers/Requests";
import { useDispatch } from "react-redux";
import moment from "moment";
import { formatDateIntoDMY } from "Utils/utils";

const TimeSheet = (props) => {
  const { sideBarPadding } = useContext(LoginContext);

  const {
    workerTimeSheetsFetchRequest,
    participantListForTimeSheetRequest,
    participantListForTimesheetLoader,
    participantListForTimesheet,
    workerTimesheetLoader,
    workerTimeSheets,
    setSelectedTimeSheetLocally,
    selectedTimeSheet,
    timeSheetDataUpdateRequest,
    currentSpUser,
    updateTimesheetLoader,
    timeSheetStatusChangeRequest,
    timesheetStatusChangeLoader,
    isAnyPendingExist,
    workerTimeSheetsLocallySearch,
    catalogStates,
    getCatalogServicesSubCategoryRequest,
    workerTimesheetPagination,
    workerTimesheetPaginationLoader,
    resetWorkerTimeSheets,

    spRosteringSettingsRequest,
    spRosteringSettingLoader,
    spRosteringSetting,
  } = props;

  const [selectedTab, setSelectedTab] = useState(ROSTERING_TIMESHEET_TABS[0]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isShowApprovalModal, setIsShowApprovalModal] = useState(false);
  // const [selectedTimeSheet, setSelectedTimeSheet] = useState(null);

  const navigate = useNavigate();

  // const handleSetSelectedTimeSheet = (timeSheet) => {
  //   setSelectedTimeSheet(timeSheet);
  // };

  useEffect(() => {
    spRosteringSettingsRequest();
    participantListForTimeSheetRequest();
    getCatalogServicesSubCategoryRequest({ isService: false });
    apiGetRequest("/roster-invoices/service-type")
      .then((res) => {
        if (res.data) {
          const modifiedValues = res?.data?.data?.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }));

          setServiceTypes(modifiedValues);
        }
      })
      .catch((error) => setServiceTypes([]));

    return () => {
      resetWorkerTimeSheets();
    };
  }, []);

  useEffect(() => {
    if (
      selectedParticipant === null &&
      participantListForTimesheet &&
      participantListForTimesheet.length > 0
    ) {
      setSelectedParticipant({
        ...participantListForTimesheet[0],
      });
    }
  }, [participantListForTimesheet]);

  useEffect(() => {
    // console.log("api call");
    if (workerTimesheetLoader === false) {
      fetchTimesheets({ page: 1 }, true);
    }
  }, [spRosteringSetting, startDate, endDate, selectedParticipant]);

  const [serviceTypes, setServiceTypes] = useState([]);

  const unSelectedMessageDiv = () => (
    <div className='py-2 text-red-500 flex justify-center items-center space-x-2'>
      <ImBlocked />
      <span className='inline-block text-red-500'>Select TimeSheet!</span>
    </div>
  );

  const handleDateChange = (dates) => {
    if (dates && dates?.startDate) {
      setStartDate(dates.startDate);
    }
    if (dates && dates?.endDate) {
      setEndDate(dates.endDate);
    }
    // fetchTimesheets({ page: 1 }, true);
    // if (
    //   dates &&
    //   dates?.startDate &&
    //   dates?.endDate &&
    //   selectedParticipant === null
    // ) {
    //   toast("Select Participant!");
    // }
  };

  const fetchTimesheets = (data = {}, isRefreshList = false) => {
    const payload = data;
    Object.assign(payload, {
      per_page: 5,
      paginationCall: data?.paginationCall || false,
    });

    if (startDate && endDate && selectedParticipant) {
      Object.assign(payload, {
        // start_date: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
        // end_date: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
        start_date: formatDateIntoDMY(startDate),
        end_date: formatDateIntoDMY(endDate),
      });

      if (selectedParticipant.value !== "all") {
        Object.assign(payload, { pt_id: selectedParticipant.value });
      }
      workerTimeSheetsFetchRequest(payload, isRefreshList);
    }
  };

  const loadTabComponent = () => {
    switch (selectedTab.value) {
      case ROSTERING_TIMESHEET.timesheet:
        return (
          <>
            {selectedTimeSheet && selectedParticipant ? (
              <TimesheetContent
                timeSheetStatusChangeRequest={timeSheetStatusChangeRequest}
                updateTimesheetLoader={updateTimesheetLoader}
                currentSpUser={currentSpUser}
                timeSheetDataUpdateRequest={timeSheetDataUpdateRequest}
                selectedParticipant={selectedParticipant}
                selectedTimeSheet={selectedTimeSheet}
                timesheetStatusChangeLoader={timesheetStatusChangeLoader}
                catalogStates={catalogStates}
                serviceTypes={serviceTypes}
                spRosteringSetting={spRosteringSetting}
              />
            ) : (
              unSelectedMessageDiv()
            )}
          </>
        );
      case ROSTERING_TIMESHEET.history:
        return (
          <>
            {selectedTimeSheet && selectedTimeSheet.uuid ? (
              <TimesheetHistory
                selectedTimeSheetHistory={selectedTimeSheet?.history || []}
              />
            ) : (
              unSelectedMessageDiv()
            )}
          </>
        );
      case ROSTERING_TIMESHEET.comments:
        return (
          <>
            {selectedTimeSheet && selectedTimeSheet.uuid ? (
              <TimesheetComment timeSheetUuid={selectedTimeSheet.uuid} />
            ) : (
              unSelectedMessageDiv()
            )}
          </>
        );
      default:
        return (
          <TimesheetContent
            updateTimesheetLoader={updateTimesheetLoader}
            currentSpUser={currentSpUser}
            timeSheetDataUpdateRequest={timeSheetDataUpdateRequest}
            selectedParticipant={selectedParticipant}
            selectedTimeSheet={selectedTimeSheet}
            spRosteringSetting={spRosteringSetting}
          />
        );
    }
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`duration-300 flex flex-col ${
          sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
        }`}
      >
        <Header
          title='Rostering Timesheet'
          btnComponent={
            <CustomButton
              label='Redirect To Schedule'
              Icon={<BiExit />}
              clickHandler={() => navigate("/rostering/schedule")}
            />
          }
          breadcrumb={
            <Breadcrumb
              pages={ROSTERING_BREADCRUMB(ROSTERING_BREADCRUMB_PAGES.TIMESHEET)}
            />
          }
        />

        {spRosteringSettingLoader ? (
          <div
            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
          >
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : (
          <main className='flex-1 relative sm:px-6 lg:px-8'>
            <div className='py-4 sticky top-20 z-50 bg-white'>
              <div className='flex items-end justify-between w-full'>
                <div className='flex space-x-2'>
                  <div className='w-[200px]'>
                    <label className='text-sm text-gray-700 font-medium leading-5'>
                      Select Participant
                    </label>

                    <SelectOption
                      loading={participantListForTimesheetLoader}
                      isDisabled={
                        participantListForTimesheetLoader ||
                        workerTimesheetLoader
                      }
                      isSearch={true}
                      handleChange={setSelectedParticipant}
                      data={participantListForTimesheet}
                      selectedOption={selectedParticipant}
                    />
                  </div>

                  <div>
                    <DateRangeSelect
                      defaultType={spRosteringSetting?.calendar_type}
                      defaultFortnightView={
                        spRosteringSetting?.default_fortnight
                      }
                      isDisabled={workerTimesheetLoader}
                      dateHandler={handleDateChange}
                    />
                  </div>
                </div>

                <div className='flex justify-end space-x-2 '>
                  {/* <div>
      <CustomButton
        variant="tertiary"
        label="Add Timesheet"
        clickHandler={() => {}}
        cssClass="text-gray-500 bg-white border border-solid border-gray-200 rounded-lg shadow-sm"
      />
    </div> */}
                  {workerTimeSheets.ttlPendingTimeSheets > 0 && (
                    <div>
                      <CustomButton
                        variant='tertiary'
                        label='Approve All'
                        clickHandler={() => setIsShowApprovalModal(true)}
                        cssClass='text-green-700 bg-green-50 border border-solid border-gray-200 rounded-lg shadow-sm'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <section>
              {!workerTimesheetPaginationLoader &&
              (workerTimesheetLoader || participantListForTimesheetLoader) ? (
                <div
                  className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <div className='flex py-4'>
                  <div className='w-full absolute md:static'>
                    <div className='min-h-full py-4'>
                      <div className='mx-auto max-w-full flex lg:gap-4 capitalize'>
                        <div className='w-[30%] rounded-lg border border-gray-300 left-side-content'>
                          <TimeSheetSideBar
                            paginationObj={workerTimesheetPagination}
                            workerTimesheetPaginationLoader={
                              workerTimesheetPaginationLoader
                            }
                            workerTimesheetLoader={workerTimesheetLoader}
                            fetchTimesheets={fetchTimesheets}
                            workerTimeSheetsLocallySearch={
                              workerTimeSheetsLocallySearch
                            }
                            isAnyPendingExist={isAnyPendingExist}
                            setSelectedTimeSheetLocally={
                              setSelectedTimeSheetLocally
                            }
                            selectedTimeSheet={selectedTimeSheet}
                            timeSheets={workerTimeSheets}
                          />
                        </div>

                        <div className='w-[70%]'>
                          <TimeSheetTabs
                            tabs={ROSTERING_TIMESHEET_TABS}
                            handleChange={(e) => setSelectedTab(e)}
                            selectedTab={selectedTab}
                          />

                          <div className='mt-5'>{loadTabComponent()}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </main>
        )}
      </div>

      {isShowApprovalModal && (
        <ApproveTimeSheetModal
          handleClose={() => setIsShowApprovalModal(false)}
          isShow={isShowApprovalModal}
        />
      )}
    </div>
  );
};

export default TimeSheet;

import React, { useEffect, useState } from "react";
import CustomButton from "components/common/Enhansment/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import PasswordSetting from "./PasswordSetting";
import {
  employeeTypeOption,
  // portalOption,
  portalTypeOptions,
  SalarySlabOptions,
  GenderOptions,
  REGEX_PHONE_NUMBER,
  USER_ROLE,
  // STRENGTH_IMPROVEMENTS_OF_WORKERS,
} from "Constants/constant";
import InputField from "components/common/Input/Input";
import { Controller, useForm } from "react-hook-form";
import FileUploadRoundedShape from "components/common/FileUpload/FileUploadRoundedShape";
import moment from "moment";
import InteractiveStarRating from "components/common/InteractiveStarRating";
import { toast } from "react-toastify";
import api from "store/services/auth.services";
import { apiPostRequest, apiPutRequest } from "helpers/Requests";
import { parseBooleanVal } from "Utils/utils";
import {
  PAYROLL_PLATFORMS,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
import WorkerPayrollSection from "./WorkerPayrollSection";
import usePayrollAuthentication from "hooks/usePayrollAuthentication";
// import { FiArrowUpRight } from "react-icons/fi";
// import { useNavigate } from "react-router";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import DeleteWorkerModal from "../../TeamManagment/WorkerQuickView/DeleteWorkerModal";
import MultiLevelDropdown from "components/common/MultilevelDropdown";
import { roleAccess } from "helpers/RolesPermission";
import api2 from "store/services/sp.services";
import { useParams } from "react-router";
import SwitchInput from "components/common/Input/SwitchInput";
const WorkerSetting = (props) => {
  const {
    worker,
    // workerHourlyRateSettingRequest,
    workerSettingCreateLoader,
    workerStatusUpdateLoader,
    workerStatusUpdated,
    keyStrength = [],
    WorkerProfileDataRequest,
    isUserGracePeriodOver,
    workerInfo,
  } = props;
  // const {workerInfo} = useParams();

  const [roleOptions, setRoleOptions] = useState([]);
  const [fetchRoleLoader, setFetchRoleLoader] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  // const [workerEntitlement, setWorkerEntitlement] = useState("");

  const { isAuthenticateForPayrolls, isCheckPayrollAuthLoading } =
    usePayrollAuthentication();

  useEffect(() => getRoles(), []);

  const getRoles = async () => {
    try {
      setFetchRoleLoader(true);
      const response = await api.roleAndPermissionList({
        type: portalTypeOptions[1].value,
      });
      if (response && response.data) {
        setRoleOptions(
          response?.data?.map((x) => ({ label: x.name, value: x.id }))
        );
      }
      setFetchRoleLoader(false);
    } catch (err) {
      setFetchRoleLoader(false);
      console.log("err", err);
    }
  };
  const [profilePicture, setProfilePicture] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [loader, setLoader] = useState(false);
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  } else {
    month = `${month}`;
  }
  let day = date.getDate();
  let completeDate = `${year}-${month}-${day}`;

  let dateToday = new Date();
  dateToday.setDate(dateToday.getDate() + 1); // Set the date to tomorrow

  let getReviewYear = dateToday.getFullYear();
  let getReviewMonth = dateToday.getMonth() + 1;
  if (getReviewMonth < 10) {
    getReviewMonth = `0${getReviewMonth}`;
  } else {
    getReviewMonth = `${getReviewMonth}`;
  }
  let dayOfPerfomance = dateToday.getDate();

  let reviewDate = `${getReviewYear}-${getReviewMonth}-${dayOfPerfomance}`;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const watchFormData = watch();

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const resultArray = [];

    for (const key in data) {
      const value = data[key];
      if (
        key.endsWith("-rating") &&
        value !== undefined &&
        value !== "" &&
        value !== 0
      ) {
        const cleanKey = key.replace(/-rating$/, ""); // Remove the trailing "-"
        const pair = {};
        pair[cleanKey] = value;
        resultArray.push(pair);
      }
    }

    const formData = new FormData();

    formData.append("salary_slab", data?.salary_slab ? data?.salary_slab : " ");
    formData.append("hourly_rate", data?.hourly_rate ? data?.hourly_rate : "");
    formData.append(
      "employee_type",
      data?.employee_type ? data?.employee_type : " "
    );
    // formData.append(
    //   "portal_access",
    //   data?.portal_access ? data?.portal_access : ""
    // );
    formData.append("portal_access", "Yes");
    formData.append("profile_id", worker?.uuid ? worker?.uuid : "");
    // formData.append(
    //   "xero_worker_id",
    //   data?.xero_worker_id ? data?.xero_worker_id : ""
    // );
    formData.append("role_id", data?.role_id);

    formData.append("first_name", data?.first_name ? data?.first_name : "");
    formData.append("last_name", data?.last_name ? data?.last_name : "");
    formData.append("dob", data?.dob ? data?.dob : "");
    formData.append("gender", data?.gender ? data?.gender : "");

    formData.append(
      "emergency_contact_phone_number",
      data?.emergency_contact_phone_number
        ? data?.emergency_contact_phone_number
        : ""
    );

    formData.append("photo", profilePicture);
    formData.append("address", data?.address ? data?.address : "");
    formData.append("phone", data?.phone ? data?.phone : "");
    formData.append("city", data?.city ? data?.city : "");
    formData.append("state", data?.state ? data?.state : "");
    formData.append("postal_code", data?.postal_code ? data?.postal_code : "");
    formData.append("country", data?.country ? data?.country : "");
    formData.append(
      "contact_email",
      data?.contact_email ? data?.contact_email : ""
    );
    formData.append(
      "quick_snapshot",
      data?.quickSnapshot ? data?.quickSnapshot : ""
    );
    formData.append(
      "worker_entitlement",
      data?.worker_entitlement ? data?.worker_entitlement : ""
    );
    //console.log("Worker Entitlement:", data.worker_entitlement);

    formData.append("risk_assessed_role", data?.risk_assessed_role ?? "");

    formData.append("key_strengths", JSON.stringify(resultArray));
    formData.append(
      "emergency_contact_name",
      data?.emergency_contact_name ? data?.emergency_contact_name : ""
    );

    if (parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.XERO])) {
      formData.append(
        "xero_worker_id",
        data?.xero_worker_id ? data?.xero_worker_id : ""
      );
    }

    formData.append("quick_books_worker_id", data?.quick_books_worker_id ?? "");
    if (
      parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.QUICKBOOKS])
    ) {
      formData.append(
        "quick_books_business_id",
        data?.quick_books_business_id
          ? parseInt(data?.quick_books_business_id)
          : ""
      );
    }

    if (parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.MYOB])) {
      formData.append(
        "myob_company_id",
        data?.myob_company_id ? data?.myob_company_id : ""
      );
      formData.append(
        "myob_worker_id",
        data?.myob_worker_id ? data?.myob_worker_id : ""
      );
      formData.append(
        "myob_payroll_category",
        data?.myob_payroll_category ? data?.myob_payroll_category : ""
      );
    }

    if (watchFormData.review_required && data?.review_date) {
      formData.append("review_date", data?.review_date);
    }
    if (watchFormData.review_required) {
      formData.append(
        "review_required",
        data?.review_required ? data?.review_required : false
      );
    }
    formData.append("is_review", data?.is_review);
    let regionsLength = selectedRegions?.length;

    if (regionsLength > 0) {
      const regionIds = selectedRegions?.map((el) => el?.id);

      formData.append("region_id", JSON.stringify(regionIds));
    }
    try {
      setLoading(true);
      const res = apiPostRequest(`hrm/worker-settings`, formData);
      res
        .then((res) => {
          if (res.status === 200) {
            WorkerProfileDataRequest({ profile_id: worker?.uuid });
            toast.success("worker settings updated successfully...");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error while submit worker setting", error);
          toast.error(error?.message ?? "Something Went Wrong!");
        });
    } catch (err) {
      console.log("err on read", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (emailChanged) {
      WorkerProfileDataRequest({ profile_id: worker?.uuid });

      setEmailChanged(false);
    }
  }, [emailChanged, worker?.uuid, WorkerProfileDataRequest]);

  const handleUpdatedEmail = async (obj) => {
    const currentEmail = watchFormData.email;

    const updatedEmail = watchFormData.updated_email;

    const data = {
      profile_id: worker.uuid,

      current_email: currentEmail,

      updated_email: updatedEmail,
    };
    try {
      setIsLoadingEmail(true);
      const res = await apiPutRequest(
        `/hrm/employee/change-email?profile_id=${worker.uuid}`,
        data
      );
      if (res.status === 200) {
        toast.success(res.data.message);
        setEmailChanged(true);
      } else {
        toast.error(res.data.message);
      }
      setIsLoadingEmail(false);
    } catch (err) {
      console.log("err", err);
      setIsLoadingEmail(false);
      toast.error(err ? err.message : "something went wrong!");
    }
  };

  const isChecked = watch("risk_assessed_role");

  const handleCheckboxChange = (e) => {
    setValue("risk_assessed_role", e.target.checked);
  };
  useEffect(() => {
    if (worker) {
      reset(worker);
    }
  }, [worker]);

  const handleChangeWorkerStatus = () => {
    if (isUserGracePeriodOver) {
      return;
    }
    workerStatusUpdated({ status: !worker.status, profile_id: worker.uuid });
  };

  const handleRatingChange = (label, value) => {
    const existingItemIndex = selectedItems.findIndex(
      (item) => item.label === label
    );

    if (existingItemIndex !== -1) {
      const updatedItems = [...selectedItems];
      updatedItems[existingItemIndex] = { label, value };
      setSelectedItems(updatedItems);
    } else {
      if (selectedItems.length <= 11) {
        setSelectedItems([...selectedItems, { label, value }]);
      } else {
        console.log("select max 12");

        toast.error("you can select max 12 (6 compulsory & 6 optional)");
      }
    }
  };
  useEffect(() => {
    setValue("review_date", watchFormData?.employee_performance?.review_date);
    setValue(
      "is_review",
      watchFormData?.employee_performance?.is_review || false
    );
  }, [
    watchFormData?.employee_performance?.review_date,
    watchFormData.employee_performance,
  ]);
  const parsedKeyStrengths = JSON.parse(watchFormData?.key_strengths || "[]");

  useEffect(() => {
    const getManagersList = async () => {
      setLoader(true);
      const res = await api2.getTeamLeadManagerAndRegions(1);

      setRegionList(res?.data?.regions);
      setLoader(false);
    };
    getManagersList();
  }, []);

  useEffect(() => {
    if (worker?.regions) {
      setSelectedRegions(worker?.regions);
    }
  }, []);

  return (
    <>
      <div className='bg-white pt-6 p-3'>
        <div className=' z-[6]'>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='grid grid-cols-12 gap-x-4'>
                <div className='col-span-8 '>
                  <label className='text-gray-700 text-lg font-bold '>
                    Personal Information
                  </label>

                  <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='First Name'
                        type='text'
                        value={""}
                        name='first_name'
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.first_name?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Last Name'
                        type='text'
                        value={""}
                        name='last_name'
                        isRequired={true}
                        redstar={true}
                        ErrorMessage={
                          errors?.last_name?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Date of Birth
                        {/* <span className='text-red-500'>*</span> */}
                      </label>
                      <input
                        {...register("dob", {
                          required: false,
                        })}
                        min={moment().format("DD-MM-YYYY")}
                        type='date'
                        className='did-floating-input mt-1'
                        max={completeDate}
                      />
                      {errors?.dob?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                    </div>
                    <div>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Gender
                        {/* <span className='text-red-500'>*</span> */}
                      </label>
                      <SelectOption
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        name='gender'
                        isSearch={false}
                        clearErrors={clearErrors}
                        data={GenderOptions}
                        // selectedOption={watchFormData?.gender}
                        selectedOption={GenderOptions.find(
                          (x) =>
                            x.value.toLowerCase() ===
                            watchFormData?.gender?.toLowerCase()
                        )}
                        errorMessage={
                          errors?.gender?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='flex  items-center gap-x-2 mb-4'>
                    <label className='text-gray-700 text-lg font-bold '>
                      Performance Review Required
                    </label>
                    <div>
                      <Controller
                        name={`review_required`}
                        control={control}
                        defaultValue={
                          watchFormData.review_required ? true : false
                        }
                        render={({ field }) => (
                          <SwitchWithIcon
                            onChange={(value) => {
                              field.onChange(value);
                              setValue(`review_required`, value);
                            }}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {watchFormData?.review_required && (
                    <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5 flex items-center'>
                      <div>
                        <label className='block text-sm font-medium text-gray-700'>
                          Due Date <span className='text-red-500'>*</span>
                        </label>
                        <input
                          {...register(`review_date`, {
                            required: true,
                          })}
                          type='date'
                          className='did-floating-input mt-1'
                          min={!watchFormData?.is_review ? reviewDate : ""}
                        />
                        {errors?.review_date?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div>
                        <div className='mt-2 flex items-center gap-x-2'>
                          <div>
                            <input
                              {...register("is_review")}
                              type='checkbox'
                              className='h-4 w-4 rounded accent-blue-dark'
                            />
                          </div>
                          <div className='block text-sm font-medium text-gray-700'>
                            <label htmlFor='is_review'>Review Completed</label>
                          </div>
                        </div>
                        <div>
                          {/* {errors?.is_review?.type === "required" && (
                            <span className="text-sm text-red-500">
                                This field is required!
                            </span>
                          )} */}
                        </div>
                      </div>
                    </div>
                  )}

                  <label className='text-gray-700 text-lg font-bold '>
                    Contact Information
                  </label>
                  <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Email'
                        type='email'
                        value={""}
                        name='contact_email'
                        redstar={false}
                        isRequired={false}
                        ErrorMessage={
                          errors?.contact_email?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Emergency Contact Name'
                        type='text'
                        value={""}
                        name='emergency_contact_name'
                        redstar={false}
                        isRequired={false}
                        ErrorMessage={
                          errors?.emergency_contact_name?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Phone Number
                        {/* <span className='text-red-500'>*</span> */}
                      </label>
                      <input
                        {...register("phone", {
                          required: false,
                          pattern: REGEX_PHONE_NUMBER,
                          onChange: (e) => {
                            const sanitizedValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            const formattedValue = sanitizedValue.slice(0, 10);
                            e.target.value = formattedValue;
                          },
                        })}
                        className='did-floating-input'
                        type='number'
                        redstar={false}
                      />
                      {errors?.phone?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {errors?.phone?.type === "pattern" && (
                        <span className='text-sm text-red-500'>
                          {/* Phone Number must be required start with 04, 1300 or
                          1800 & exactly 10 digits */}
                          Please enter a valid phone number!
                        </span>
                      )}
                    </div>

                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Emergency Contact Number{" "}
                        {/* <span className='text-red-500'>*</span> */}
                      </label>
                      <input
                        {...register("emergency_contact_phone_number", {
                          required: false,
                          pattern: {
                            value: REGEX_PHONE_NUMBER,
                            message:
                              // "Phone Number must be required start with 04, 1300 or 1800 & exactly 10 digits",
                              "Please enter a valid phone number!",
                          },
                          onChange: (e) => {
                            const sanitizedValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            const formattedValue = sanitizedValue.slice(0, 10);
                            e.target.value = formattedValue;
                          },
                        })}
                        className='did-floating-input'
                        type='number'
                        redstar={false}
                      />
                      {errors?.emergency_contact_phone_number?.message && (
                        <span className='text-sm text-red-500'>
                          {errors.emergency_contact_phone_number.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <label className='text-gray-700 text-lg font-bold '>
                    Address
                  </label>
                  <div className='grid  md:grid-cols-1 gap-y-4 gap-x-5 mt-2'>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Street Address'
                        type='text'
                        value={""}
                        name='address'
                        isRequired={false}
                        redstar={false}
                        ErrorMessage={
                          errors?.address?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Suburb'
                        type='text'
                        value={""}
                        name='city'
                        redstar={false}
                        isRequired={false}
                        ErrorMessage={
                          errors?.city?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='State'
                        type='text'
                        value={""}
                        redstar={false}
                        name='state'
                        isRequired={false}
                        ErrorMessage={
                          errors?.state?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Postal Code'
                        type='number'
                        value={""}
                        redstar={false}
                        name='postal_code'
                        isRequired={false}
                        ErrorMessage={
                          errors?.postal_code?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        setValue={setValue}
                        register={register}
                        label='Country'
                        type='text'
                        value={""}
                        name='country'
                        isRequired={false}
                        redstar={false}
                        ErrorMessage={
                          errors?.country?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor='quickSnapshot'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Quick Snapshot
                      </label>
                      <div className='mt-1'>
                        <textarea
                          rows={3}
                          name='quickSnapshot'
                          id='quickSnapshot'
                          defaultValue={
                            watchFormData.quick_snapshot
                              ? watchFormData.quick_snapshot
                              : ""
                          }
                          className='px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md resize-none overflow-y-auto v ln lk'
                          {...register("quickSnapshot", {
                            //required: true,
                          })}
                        />
                      </div>
                    </div>
                    <div>
                      <InputField
                        label='Worker Entitlement'
                        setValue={setValue}
                        register={register}
                        //label="Worker Entitlement"
                        type='number'
                        // min='0'
                        maxLength={3}
                        name='worker_entitlement'
                        isRequired={false}
                        redstar={false}
                        ErrorMessage={
                          errors?.worker_entitlement?.type === "required"
                            ? "This field is required!"
                            : errors?.worker_entitlement?.type === "min"
                            ? "Invalid hours"
                            : errors?.worker_entitlement?.type === "maxLength"
                            ? "Maximum Length should be 3."
                            : null
                        }
                      />
                    </div>
                    <div className='mt-2'>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <input
                            {...register("risk_assessed_role")}
                            type='checkbox'
                            className='h-4 w-4 rounded accent-blue-dark'
                            checked={!!isChecked}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className='block text-sm font-medium text-gray-700'>
                          <label
                            htmlFor='risk_assessed_role'
                            style={{ marginLeft: "0.5rem" }}
                          >
                            Risk Assessed Roles
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='lg:col-span-4 sm:col-span-12 flex justify-center mt-8'>
                  <div className='text-center'>
                    <label className='text-gray-700 text-sm font-bold mb-1'>
                      Profile Picture
                    </label>
                    <div className='mt-3'>
                      <FileUploadRoundedShape
                        setProfilePicture={setProfilePicture}
                        profilePicture={profilePicture}
                        ImgURL={worker?.photo}
                      />
                      <label
                        htmlFor='file-upload'
                        className='relative  mt-3 cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 ml-5'
                      >
                        <span>Attach a picture</span>
                      </label>
                    </div>
                    <span className='text-xs text-gray-400 ml-1'>
                      (File size should not be greater than 6MB)
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <label className='text-gray-700 text-lg font-bold '>
                  Key Strengths,Likes & Areas of Improvement (Maximum 12 )
                </label>
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                  {keyStrength &&
                    keyStrength?.map((item, index) => {
                      const strengthValue = parsedKeyStrengths.find(
                        (strength) => Object.keys(strength)[0] === item.label
                      );

                      const initialValue = strengthValue
                        ? strengthValue[item.label]
                        : 0;

                      return (
                        <div key={index}>
                          <InteractiveStarRating
                            label={item.label}
                            initialValue={initialValue}
                            name={item.label}
                            setValue={setValue}
                            // required={item.required}
                            required={false}
                            register={register}
                            control={control}
                            errors={errors}
                            onRatingChange={(name, value) =>
                              handleRatingChange(item.label, value)
                            }
                            selectedValue={selectedItems.find(
                              (selectedItem) =>
                                selectedItem.label === item.label
                            )}
                            selectedItems={selectedItems}
                            isDisabled={selectedItems.length === 12}
                            setSelectedItems={setSelectedItems}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                <div>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Employee Type
                    {/* <span className='text-red-500'>*</span> */}
                  </label>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    name='employee_type'
                    clearErrors={clearErrors}
                    isSearch={false}
                    data={employeeTypeOption}
                    selectedOption={employeeTypeOption.find(
                      (x) => x.value === watchFormData?.employee_type
                    )}
                    errorMessage={
                      errors?.employee_type?.type === "required"
                        ? "This field is required!"
                        : null
                    }
                  />
                </div>
                <div>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Salary Slab
                    {/* <span className='text-red-500'>*</span> */}
                  </label>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='salary_slab'
                    isSearch={false}
                    data={SalarySlabOptions}
                    selectedOption={SalarySlabOptions.find(
                      (x) => x.value === watchFormData?.salary_slab
                    )}
                    errorMessage={
                      errors?.salary_slab?.type === "required"
                        ? "This field is required!"
                        : null
                    }
                  />
                </div>
                {/* <div>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Portal Access
                    {/* <span className='text-red-500'>*</span> */}
                {/* </label>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    name='portal_access'
                    isSearch={false}
                    clearErrors={clearErrors}
                    data={portalOption}
                    selectedOption={portalOption.find(
                      (x) =>
                        x.value.toLowerCase() ===
                        watchFormData?.portal_access?.toLowerCase()
                    )}
                    errorMessage={
                      errors?.portal_access?.type === "required"
                        ? "This field is required!"
                        : null
                    }
                  />
                </div> */}
                <div>
                  <InputField
                    setValue={setValue}
                    register={register}
                    isGeneralPattern={true}
                    pattern={/^\d*[0-9][.]?\d*$/i}
                    label='Hourly Rate'
                    redstar={false}
                    type='text'
                    value={watchFormData?.hourly_rate ?? null}
                    name='hourly_rate'
                    isRequired={false}
                    ErrorMessage={
                      errors?.hourly_rate?.type === "required"
                        ? "This field is required!"
                        : errors?.hourly_rate?.type === "pattern"
                        ? "Only number allow"
                        : null
                    }
                  />
                </div>
                <div>
                  <label className='block text-sm font-medium text-gray-700'>
                    Role
                  </label>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='role_id'
                    loading={fetchRoleLoader}
                    isDisabled={fetchRoleLoader}
                    //
                    isSearch={false}
                    data={roleOptions}
                    selectedOption={
                      watchFormData?.role_id
                        ? roleOptions.find(
                            (x) => x.value === watchFormData?.role_id
                          )
                        : null
                    }
                    errorMessage={
                      errors?.role_id?.type === "required"
                        ? "This field is required!"
                        : null
                    }
                  />
                </div>
                {regionList?.length > 0 && (
                  <div className='col-span-1 sm:col-span-1'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Regions
                    </label>
                    <MultiLevelDropdown
                      regions={regionList}
                      setSelectedItem={setSelectedRegions}
                      selectedItems={selectedRegions}
                      parentRegionLoader={loader}
                      multiSelect
                    />
                  </div>
                )}

                <div className='col-span-2'>
                  <div className='mb-3 py-4 border-b border-gray-300' />
                  <label className='text-gray-700 text-lg font-bold py-2'>
                    Payrolls
                  </label>
                  <WorkerPayrollSection
                    worker={worker}
                    register={register}
                    watchFormData={watchFormData}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    errors={errors}
                    isAuthenticateForPayrolls={isAuthenticateForPayrolls}
                    isCheckPayrollAuthLoading={isCheckPayrollAuthLoading}
                  />
                </div>

                <div className='col-span-2'>
                  <div className='pt-4 flex justify-end items-center'>
                    <CustomButton
                      type='submit'
                      isDisabled={
                        workerSettingCreateLoader ||
                        fetchRoleLoader ||
                        isCheckPayrollAuthLoading ||
                        loading ||
                        isUserGracePeriodOver
                      }
                      showLoading={workerSettingCreateLoader || loading}
                      cssClass='px-5'
                      label='Update Setting'
                    />
                  </div>
                </div>
              </div>
            </form>
            {roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) && (
              <>
                <div className='mb-3 py-4 border-b border-gray-300'></div>
                <label className='text-gray-700 text-lg font-bold py-2'>
                  Login Email Address
                </label>

                <div className='grid grid-cols-2 gap-y-4 gap-x-5 pt-4 items-center'>
                  <div>
                    <InputField
                      label='Current Email'
                      setValue={setValue}
                      register={register}
                      type='text'
                      //value='email'
                      name='current_email'
                      readOnly
                      defaultValue={watchFormData.email}
                    />
                  </div>
                  <div>
                    <InputField
                      label='New Email'
                      setValue={setValue}
                      register={register}
                      type='text'
                      name='updated_email'
                    />
                  </div>

                  <div className='col-span-2'>
                    <div className='pt-4 flex justify-end items-center'>
                      <CustomButton
                        isDisabled={isLoadingEmail || isUserGracePeriodOver}
                        showLoading={isLoadingEmail}
                        cssClass='px-5'
                        label='Change Email'
                        clickHandler={handleUpdatedEmail}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='py-4 border-b border-gray-300'></div>
            <PasswordSetting
              isUserGracePeriodOver={isUserGracePeriodOver}
              worker={worker}
            />

            <div className='py-4 border-b border-gray-300'></div>
            <div className='pt-4 flex justify-between items-center'>
              <div>
                <h4
                  className={`${
                    worker && worker.status ? "text-red-600" : "text-green-600"
                  } font-bold`}
                >
                  {worker && worker.status ? "Deactivate" : "Activate"} User
                </h4>
                <p className='text-sm text-gray-600'>
                  {worker && worker.status
                    ? "This will deactivate the user and they will not be able to access their account again!"
                    : "This will activate the user and they will be able to access their account!"}
                </p>
              </div>
              <CustomButton
                isDisabled={
                  workerStatusUpdateLoader ||
                  isCheckPayrollAuthLoading ||
                  loading ||
                  isUserGracePeriodOver
                }
                showLoading={workerStatusUpdateLoader}
                variant={worker && worker.status ? "danger" : "tertiary"}
                cssClass={`${
                  worker && worker.status
                    ? ""
                    : "border border-green-500 text-green-700 bg-green-300 hover:bg-green-400"
                } px-5`}
                label={worker && worker.status ? "Deactivate" : "Activate"}
                clickHandler={handleChangeWorkerStatus}
              />
            </div>
            {roleAccess(
              [],
              PERMISSION_KEYS.HRM,
              PERMISSION_KEYS_CHILDREN.HRM.WORKER.DELETE
            ) && (
              <>
                <div className='py-4 border-b border-gray-300'></div>
                <div className='pt-4 flex justify-between items-center'>
                  <div>
                    <h4 className='text-red-600 font-bold'>Delete User</h4>
                    <p className='text-sm text-gray-600'>
                      This will permanently delete the worker data. You will not
                      be able to access the Worker later.
                    </p>
                  </div>

                  <CustomButton
                    type='button'
                    variant='danger'
                    cssClass='px-3 py-2 text-right sm:px-2 space-x-4'
                    label='Delete Worker'
                    clickHandler={() => setIsShowModal(true)}
                  />
                </div>
              </>
            )}

            {isShowModal && (
              <DeleteWorkerModal
                isUserGracePeriodOver={isUserGracePeriodOver}
                handleClose={() => setIsShowModal(false)}
                isShow={isShowModal}
              />
            )}

            {isShowModal && (
              <DeleteWorkerModal
                isUserGracePeriodOver={isUserGracePeriodOver}
                handleClose={() => setIsShowModal(false)}
                isShow={isShowModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkerSetting;

import React, { useEffect, useState, useRef } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { apiPostRequest } from "../../../../helpers/Requests";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderButton from "../../../../components/common/LoaderButton/LoaderButton";
import PasswordValidationBox from "components/common/PasswordValidationBox/PasswordValidationBox";
import {
  EMAIL_REGEX_EXP,
  NUMBER_OF_PARTICIPANTS,
  REGEX_PHONE_NUMBER,
  STATES_LIST,
} from "Constants/constant";
import SelectOption from "components/common/Select/Select";
import "./PersonalDetail.css";
import ReCAPTCHA from "react-google-recaptcha";
import { HiRefresh } from "react-icons/hi";
import { validateNoLeadingSpaces } from "Utils/FormValidation";
// import TermsAndConditionsPopup from "pages/TermsandConditionsPopup/TermsAndConditionsPopup";

const PersonalDetail = ({ submitHandler }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPassword, setconfirmPassword] = useState("password");
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [ischecked, setischecked] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const [refQuery] = useState(queryParameters.get("ref") || null);
  const [primary_contact_name_query] = useState(queryParameters.get("name") || null);
  const [email_query] = useState(queryParameters.get("email") || null);

  const showPasswordHandler = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const showConfirmPasswordHandler = () => {
    confirmPassword === "password"
      ? setconfirmPassword("text")
      : setconfirmPassword("password");
  };
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    trigger,
    formState: { errors },
  } = useForm();

  const watchFormData = watch();
  useEffect(() => {
    if (refQuery && refQuery !== watchFormData?.referred_by) {
      setValue("referred_by", refQuery);
    }
    if (primary_contact_name_query && primary_contact_name_query !== null) {
      setValue("primary_contact_name", primary_contact_name_query);
    }
    if (email_query && email_query !== null) {
      setValue("email", email_query);
    }
  }, [refQuery]);

  const registerHandler = async (data) => {
    setRequest({ ...request, isRequested: true });

    const newData = { ...data, state: catalogState?.value };
    try {
      //newData.agree_term = data.agree_term;
      let res = await apiPostRequest("provider-signup", newData);
      if (res.status !== 201) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error("Something went wrong");
      }

      if (res.status === 201) {
        const encode = window.btoa(encodeURIComponent(data.email));
        setRequest({ ...request, isRequested: false, isSuccess: true });
        navigate(`/email-verification-code/${encode}`);
        toast.success("Service Provider Added");
      }
    } catch (err) {
      console.log("err", err);
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const onSubmit = (data) => {
    if (pass && confrimPassword && pass !== confrimPassword) {
      return;
    }
    registerHandler(data);

    if (!data.google_recaptcha) {
      alert("Please complete the reCAPTCHA!");
      return;
    }
    // Proceed with form submission
    console.log("Form data:", data);
  };

  const [pass, setPass] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const passwordFocus = () => {
    document.getElementById("message").style.display = "block";
  };

  const passwordBluer = () => {
    document.getElementById("message").style.display = "none";
  };
  const CpasswordHanlder = (value) => {
    setConfirmPassword(value);
  };

  // When the user starts to type something inside the password field
  const passwordChangeHandler = (value) => {
    // Validate lowercase letters
    setPass(value);
    // props.setFieldValue('password', value);
  };

  const [catalogState, setcatalogState] = useState();
  const [participants, setparticipants] = useState();

  const openTermsPopup = () => {
    setShowTermsPopup(true);
  };

  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  };

  const agreeToTerms = () => {
    setTermsAccepted(true);
    closeTermsPopup();
  };

  const recaptchaRef = useRef(null);
  const [isCaptchaResetting, setIsCaptchaResetting] = useState(false);

  const onChange = (value) => {
    setIsCaptchaResetting(false); // Reset the state to allow error messages
    setValue("google_recaptcha", value, { shouldValidate: true }); // Set the value and trigger validation
  };

  const onResetCaptcha = () => {
    setIsCaptchaResetting(true); // Prevent error messages during reset
    recaptchaRef.current.reset();
    setValue("google_recaptcha", ""); // Clear the reCAPTCHA value
    trigger("google_recaptcha"); // Manually trigger validation
  };

  return (
    <div className='min-h-full flex items-center justify-center py-1 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <div className='flex justify-center'>
            <div className='rounded-full bg-layer/40 h-20 w-20 flex items-center justify-center'>
              <div className='h-14 w-14 rounded-full bg-blue-200/60 flex items-center justify-center'>
                <HiOutlineUser className='h-8 w-8 text-blue-dark' />
              </div>
            </div>
          </div>
          <h2 className='mt-6 text-center text-3xl font-semibold text-gray-900'>
            Join Vertex360 Today
          </h2>
          <p className='mt-2 text-center text-md text-gray-500 font-normal'>
            Start your journey with us and enjoy a 7-day free trial!
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className=''>
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Service Provider Name
            </label>
            <input
              {...register("name", {
                required: true,
                maxLength: 254,
                validate: validateNoLeadingSpaces,
              })}
              className='did-floating-input'
              type='text'
            />
            {errors?.name?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
            {errors?.name?.type === "validate" && (
              <span className='text-sm text-red-500'>
                {errors.name.message}
              </span>
            )}
          </div>
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Primary Contact Name
            </label>
            <input
              {...register("primary_contact_name", {
                required: true,
                maxLength: 254,
                validate: validateNoLeadingSpaces,
              })}
              className='did-floating-input'
              type='text'
            />
            {errors?.primary_contact_name?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
            {errors?.primary_contact_name?.type === "validate" && (
              <span className='text-sm text-red-500'>
                {errors.primary_contact_name.message}
              </span>
            )}
          </div>
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Contact No.
            </label>
            <input
              {...register("phone", {
                required: true,
                pattern: REGEX_PHONE_NUMBER,
                // minLength: 10,
                onChange: (e) => {
                  const sanitizedValue = e.target.value.replace(/\D/g, "");
                  const formattedValue = sanitizedValue.slice(0, 10);
                  e.target.value = formattedValue;
                },
              })}
              className='did-floating-input'
              // placeholder="Enter contact number"
              type='number'
            />
            {errors?.phone?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
            {/* {errors?.phone?.type === "maxLength" && (
              <span className="text-sm text-red-500">
                Please enter a valid phone number! max 10
              </span>
            )} */}
            {errors?.phone?.type === "minLength" && (
              <span className='text-sm text-red-500'>
                Phone number must have 10 digits
              </span>
            )}
            {errors?.phone?.type === "pattern" && (
              <span className='text-sm text-red-500'>
                {/* Phone number must start with 04, 1300 or 1800 */}
                Please enter a valid phone number!
              </span>
            )}
          </div>
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Street Address
            </label>
            <input
              {...register("street_address", {
                required: true,
                maxLength: 254,
                validate: validateNoLeadingSpaces,
              })}
              className='did-floating-input'
              // placeholder="Enter street address "
              type='text'
            />
            {errors?.street_address?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
            {errors?.street_address?.type === "validate" && (
              <span className='text-sm text-red-500'>
                {errors.street_address.message}
              </span>
            )}
          </div>
          <div className='flex space-x-2'>
            <div className='mt-1 did-floating-label-content w-1/2'>
              <div className='hidden'>
                <select
                  {...register("state", {
                    // value: false
                    // required: true,
                  })}
                  name='state'
                  id=''
                  className='hidden did-floating-input'
                  // defaultValue={false}
                />
              </div>
              <label className='block mb-1 text-sm font-medium text-gray-700'>
                State
              </label>
              <SelectOption
                isSearch={false}
                data={STATES_LIST}
                selectedOption={catalogState}
                handleChange={setcatalogState}
              />
              {errors.state && (
                <span className='text-red-500'>This field is required</span>
              )}
            </div>
            <div className='mt-1 did-floating-label-content w-1/2'>
              <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
                Suburb
              </label>
              <input
                {...register("suburb", {
                  required: true,
                  maxLength: 254,
                  validate: validateNoLeadingSpaces,
                })}
                className='did-floating-input'
                type='text'
              />
              {errors?.suburb?.type === "required" && (
                <span className='text-sm text-red-500'>required!</span>
              )}
              {errors?.suburb?.type === "validate" && (
                <span className='text-sm text-red-500'>
                  {errors.suburb.message}
                </span>
              )}
            </div>
            <div className='mt-1 did-floating-label-content w-1/2'>
              <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
                Postcode
              </label>
              <input
                {...register("postcode", {
                  required: true,
                  onChange: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  },
                })}
                className='did-floating-input'
                type='number'
              />
              {errors?.postcode?.type === "required" && (
                <span className='text-sm text-red-500'>required!</span>
              )}
            </div>
          </div>
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              ABN
            </label>
            <input
              {...register("abn", {
                required: true,
                maxLength: 11,
                minLength: 11,
                pattern: "[0-9]*",
                onChange: (e) =>
                  (e.target.value = e.target.value.toString().slice(0, 11)),
              })}
              className='did-floating-input'
              // placeholder="Enter ABN number"
              type='number'
              id='abn'
            />
            {errors?.abn?.type === "required" ? (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            ) : errors?.abn?.type === "maxLength" ? (
              <span className='text-sm text-red-500'>
                Please enter a valid ABN number max 11 digits.
              </span>
            ) : errors?.abn?.type === "minLength" ? (
              <span className='text-sm text-red-500'>
                Please enter a valid ABN number min 11 digits.
              </span>
            ) : null}
          </div>
          {/* <div className="mt-1 did-floating-label-content">
            <label className="block text-sm font-medium text-gray-700 mb-[3px]">
              ACN
            </label>
            <input
              {...register("asn", {
                required: true,
                pattern: "[0-9]*",
              })}
              className="did-floating-input"
              // placeholder="Enter ACN number"
              type="number"
            />
            {errors?.asn?.type === "required" ? (
              <span className="text-sm text-red-500">
                This field is required!
              </span>
            ) : errors.asn?.type === "pattern" ? (
              <span className="text-sm text-red-500">
                Please enter a valid number
              </span>
            ) : null}
          </div> */}
          <div className='mt-1 did-floating-label-content'>
            <label className='text-gray-700 text-sm font-medium mb-1'>
              Number of Participants
            </label>
            <SelectOption
              isRequired={true}
              register={register}
              setValue={setValue}
              name='number_of_participants'
              isSearch={false}
              clearErrors={clearErrors}
              data={NUMBER_OF_PARTICIPANTS}
              // selectedOption={watchFormData?.gender}
              selectedOption={NUMBER_OF_PARTICIPANTS.find(
                (x) =>
                  x.value.toLowerCase() ===
                  watchFormData?.number_of_participants?.toLowerCase()
              )}
            />
            {errors?.number_of_participants?.type === "required" ? (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            ) : null}
          </div>
          {/* <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Referral Code
            </label>
            <input
              {...register("referred_by", {
                required: false,
              })}
              className='did-floating-input'
              // placeholder="Enter ABN number"
              type='text'
              id='referred_by'
            />
          </div> */}
          <div className='mt-1 did-floating-label-content'>
            <label className='block text-sm font-medium text-gray-700 mb-[3px]'>
              Email Address (to be used as Admin account)
            </label>
            <input
              {...register("email", {
                required: true,
                pattern: EMAIL_REGEX_EXP,
              })}
              className='did-floating-input'
              // placeholder="Enter email address"
              type='email'
            />
            {errors?.email?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
            {errors?.email?.type === "pattern" && (
              <span className='text-sm text-red-500'>
                Please enter a valid email address!
              </span>
            )}
          </div>
          <div className='space-y-4'>
            <div>
              <div className='did-floating-label-content'>
                <label
                  htmlFor='email-address'
                  className='block text-sm font-medium text-gray-700 mb-[3px]'
                >
                  Password
                </label>
                <div className='relative'>
                  <input
                    {...register("password", {
                      required: true,
                      onChange: (e) => {
                        passwordChangeHandler(e.target.value);
                      },
                    })}
                    onFocus={passwordFocus}
                    onBlur={passwordBluer}
                    id='password'
                    type={passwordType}
                    pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                    title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                    autoComplete='password'
                    className='did-floating-input'
                    // placeholder="Enter Password"
                  />
                  <span
                    onClick={showPasswordHandler}
                    className='pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark'
                  >
                    {passwordType === "password" ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
              </div>
              {errors?.password?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
              <div id='message'>
                <PasswordValidationBox password={watchFormData?.password} />
              </div>
            </div>
            <div className=''>
              <div className='did-floating-label-content'>
                <label
                  htmlFor='email-address'
                  className='block text-sm font-medium text-gray-700 mb-[3px]'
                >
                  Confirm Password
                </label>
                <div className='relative'>
                  <input
                    {...register("password_confirmation", {
                      required: true,
                      onChange: (e) => {
                        CpasswordHanlder(e.target.value);
                      },
                    })}
                    id='confirm_password'
                    type={confirmPassword}
                    autoComplete='password'
                    className='did-floating-input'
                    // placeholder="Confirm passord "
                  />
                  <span
                    onClick={showConfirmPasswordHandler}
                    className='pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark'
                  >
                    {confirmPassword === "password" ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
              </div>
              {errors?.password_confirmation?.type === "required" && (
                <div className='-mt-4 mb-4 text-sm text-red-500'>
                  This field is required!
                </div>
              )}
              {pass && confrimPassword && pass === confrimPassword ? (
                <div className='-mt-4 mb-4 text-green-500'>
                  Password matched!
                </div>
              ) : pass && confrimPassword && pass !== confrimPassword ? (
                <div className='-mt-4 mb-4 text-red-500'>
                  The passwords you entered do not match!
                </div>
              ) : null}
            </div>
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <input
                {...register("agree_term", { required: true })}
                id='agree_terms'
                //name="agree_terms"
                type='checkbox'
                className='h-4 w-4 rounded accent-blue-dark'
                checked={ischecked}
                onChange={() => setischecked(!ischecked)}
              />
              {"  "}
              <label
                htmlFor='agree_terms'
                className='text-sm text-gray-700 ml-2'
              >
                {" "}
                I have read and agree to the{" "}
                <a
                  href='https://vertex360.io/terms-and-conditions'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-dark'
                >
                  <b>Terms of Service</b>
                </a>{" "}
                &{" "}
                <a
                  href='https://vertex360.io/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-dark'
                >
                  <b>Privacy Policy</b>
                </a>
                .
              </label>
            </div>
          </div>
          {!ischecked && errors?.agree_term?.type === "required" && (
            <span className='text-sm text-red-500 pb-4'>
              Please review and agree to our legal terms in order to proceed.
            </span>
          )}

          <div className='flex flex-col mt-5'>
            <div>
              {" "}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey='6Lcs1QsqAAAAABya2njjIU6W6NK9FmM_l42csftl'
                onChange={onChange}
                size='normal'
                theme='light'
              />
            </div>
            <input
              type='hidden'
              {...register("google_recaptcha", {
                required:
                  "Please verify that you're not a robot and try again.",
              })}
            />
            <button
              type='button'
              onClick={onResetCaptcha}
              className='flex items-center text-xs text-blue-dark mt-2'
            >
              <HiRefresh className='h-4 w-4 text-blue-dark mr-1' />
              Refresh CAPTCHA
            </button>
          </div>

          {errors?.google_recaptcha && !isCaptchaResetting && (
            <span className='text-sm text-red-500'>
              {errors.google_recaptcha.message}
            </span>
          )}
          <div className='flex items-center justify-between mt-5'>
            {request.isRequested ? (
              <LoaderButton
                classes='w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                textbutton='Loading...'
              />
            ) : (
              <button
                disabled={request.isRequested}
                type='submit'
                className='
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-blue-dark
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                    '
              >
                Sign Up Now
              </button>
            )}
          </div>
          <div className='text-center pt-4'>
            No commitment, 7-day free trial included!
          </div>
          <div className='text-center pt-4'>
            Do you have already an accout?{" "}
            <Link to='/' className='font-semibold text-blue-dark'>
              Login
            </Link>
          </div>
        </form>
      </div>
      {/* <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        ltr={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {/* <TermsAndConditionsPopup
        isOpen={showTermsPopup}
        onClose={closeTermsPopup}
        onAgree={agreeToTerms}
      /> */}
    </div>
  );
};

export default PersonalDetail;
